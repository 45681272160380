var validator = {},
    OBJECT_ID_LENGTH = 24;

validator.isValidMailChimpTemplate = function isValidMailChimpTemplate(args){
    return !!args
        && validator.isValidGuid(args.userId)
        && typeof args.mailType === "number"
        && !!args.message
        && !!args.message.to
        && args.message.to.length > 0
        && !!args.message.subject
        && !!args.templateName
        && !!args.templateContent
}

validator.isValidMailChimpMessage = function isValidMailChimpMessage(args){
    return !!args
        && validator.isValidGuid(args.userId)
        && typeof args.mailType === "number"
        && !!args.message
        && !!args.message.to
        && args.message.to.length > 0
        && !!args.message.subject
        && (!!args.message.html || !!args.message.text || !!args.message.attachments)
}

validator.isValidGuid = function isValidGuid(guid) {
    return !!guid && guid.length === OBJECT_ID_LENGTH
        && (/^[0-9a-fA-F]{24}$/).test(guid)
        && typeof guid === "string";
};

module.exports = validator;
