var mailCommon = {},
    moment = require('moment-timezone'),
    formatter = require('./formatter'),
    Q = require("q");

mailCommon.setMailchimpMessageObject = function setMailchimpMessageObject(args){
    if(!!args.sendAt){
        args.send_at = args.sendAt;
    }
    args.message.from_name = args.fromName || "Plannie App";
    args.message.from_email = args.fromMail || "admin@plannieapp.com";
    args.message.track_clicks = true;
    args.message.track_opens = true;
}

mailCommon.getMailchimpMessageInfo = function getMailchimpMessageInfo(messageId) {
    let deferred = Q.defer();

    return deferred.promise;
}

mailCommon.handleMailchimpResponse = function handleMailchimpResponse(response, mailObj, logger){
    let responseLog = !!mailObj.templateName ? "mailchimpTemplate" : "mailchimpMessage"
    logger.info(`${responseLog} response: ` + JSON.stringify(response));
    if(!!response && Array.isArray(response)){
        mailObj.messageIds = response.map(x => x._id);
        mailObj.provider = "mailchimp";
        mailObj.createdDate = new Date();
        if(response.length === mailObj.to.length){
            mailObj.sent = response.every(x => x.status === "sent" || x.status === "scheduled" || x.status === "queued");
        }
        else{
            mailObj.sent = false;
            logger.err(`mailchimp fail to send mailObj, response array length: ${response.length} args.to array length: ${mailObj.to.length}`);
        }
    }
    else{
        mailObj.provider = "mailchimp";
        mailObj.createdDate = new Date();
        mailObj.sent = false;
        logger.err(`mailchimp fail to send mailObj, response is invalid ${response}`);
    }
}

// input: javascript date object
mailCommon.getSendAtTimeFormatted = function getSendAtTimeFormatted(sendDate){
    return moment(sendDate).format('YYYY-MM-DD HH:mm:SS');
}

mailCommon.saveMailInDb = function saveMailInDb(mailObj, db){
    let deferred = Q.defer();

    delete mailObj.html;
    delete mailObj.attachments;
    db.collection("mails").insert(mailObj, function (err, result){
        if(!err && result.result.ok === 1 && result.insertedCount === 1 && !!result.insertedIds){
            deferred.resolve("success");
        }
        else{
            deferred.reject("mail sent and fail to save to db, err: "+ JSON.stringify(err) + " mailObj: " + JSON.stringify(mailObj));
        }
    });

    return deferred.promise;
}

module.exports = mailCommon;
