var moment = require('moment-timezone');

module.exports = function EventValidationsService() {
    var that = this;


    this.isValidEventTimes = function isValidEventTimes(startTime, endTime, tz, applyValidationBetweenDays = true) {
        if(!!startTime && !!endTime){
            let startDate = moment(startTime).tz(tz);
            let endDate = moment(endTime).tz(tz);

            let baseValidation = startDate.unix() > 0
                                && endDate.unix() > 0
                                && endDate.unix() - startDate.unix() > 0;

            return applyValidationBetweenDays === false ?
                baseValidation :
                baseValidation && (that.isSameDay(startDate, endDate) || that.isEndDateMidnightSameDay(startDate, endDate))

        }

        return false;
    };

    //input is moment date object
    this.isSameDay = function (startDate, endDate){
        let start = startDate.clone()
        let end = endDate.clone();

        return start.startOf('day').unix() === end.startOf('day').unix()
    }

    //input is moment date object
    this.isEndDateMidnightSameDay = function (startDate, endDate){
        let start = startDate.clone()
        let end = endDate.clone();

        return end.hours() === 0 && (start.add(1, "days").startOf('day').unix() === end.startOf('day').unix());
    }

    this.isValidEventLength = function isValidEventLength(startTime, endTime, tz) {
        if (!!startTime && !!endTime && that.isValidEventTimes(startTime, endTime, tz)) {
            var endDate = moment(endTime);
            var startDate = moment(startTime);
            return endDate.diff(startDate, 'minutes') >= 10;
        }
        return false;
    };

    this.isEventOverlaps = function isEventOverlaps(eventToCreate, existingEvents, tz) {
        var result = false;
        if (!!eventToCreate && that.isValidEventTimes(eventToCreate.startTime, eventToCreate.endTime, tz) && !!existingEvents && existingEvents.length > 0) {
            existingEvents.forEach(function (existingEvent) {
                if ((that.isValidEventTimes(existingEvent.startTime, existingEvent.endTime, tz)) &&
                    ((existingEvent.startTime < eventToCreate.startTime && existingEvent.endTime > eventToCreate.startTime) ||
                    (existingEvent.startTime <= eventToCreate.startTime && existingEvent.endTime >= eventToCreate.endTime) ||
                    (existingEvent.startTime > eventToCreate.startTime && existingEvent.startTime < eventToCreate.endTime) ||
                    (existingEvent.endTime > eventToCreate.startTime && existingEvent.endTime < eventToCreate.endTime))) {
                        result = true;
                        return;
                }
            });
        }

        return result;
    };

    this.getEventOverlaps = function getEventOverlaps(eventToCreate, existingEvents, tz) {
        var result = [];
        if (!!eventToCreate && that.isValidEventTimes(eventToCreate.startTime, eventToCreate.endTime, tz) && !!existingEvents && existingEvents.length > 0) {
            existingEvents.forEach(function (existingEvent) {
                if ((that.isValidEventTimes(existingEvent.startTime, existingEvent.endTime, tz)) &&
                    ((existingEvent.startTime < eventToCreate.startTime && existingEvent.endTime > eventToCreate.startTime) ||
                    (existingEvent.startTime <= eventToCreate.startTime && existingEvent.endTime >= eventToCreate.endTime) ||
                    (existingEvent.startTime > eventToCreate.startTime && existingEvent.startTime < eventToCreate.endTime) ||
                    (existingEvent.endTime > eventToCreate.startTime && existingEvent.endTime < eventToCreate.endTime))) {
                        result.push(existingEvent)
                }
            });
        }

        return result;
    }

};
