var EventValidationsService = require('./eventsValidations-service'),
    moment = require('moment-timezone');


module.exports = function DynamicService() {
    var that = this;
    var eventValidationsService = new EventValidationsService();

    this.isServiceInsideWindow = function isServiceInsideWindow(window, chosenService) {
        var result = false;

        if(!!window && that.isValidServices(window.activeServices) && !!chosenService && !!chosenService.serviceId){

            window.activeServices.forEach(function (service) {
                if(service.serviceId === chosenService.serviceId){
                    result = true;
                    return;
                }
            });
        }

        return result;
    };

    this.isWindow = function isWindow(event) {
        return !!event && that.isValidServices(event.activeServices);
    };

    this.isValidServices = function isValidServices(services) {
        var result = true;

        if(!!services  && services.length > 0){
            services.forEach(function (service) {
                if(!service.serviceId || !service.name || !service.durationInMinutes){
                    result= false;
                }
            })
        }
        else{
            result = false;
        }

        return result;
    };

    this.isValidServicesToInsert = function isValidServicesToInsert(services) {
        var result = true;

        if(!!services  && services.length > 0){
            services.forEach(function (service) {
                if(!service.name || !service.durationInMinutes){
                    result= false;
                }
            })
        }
        else{
            result = false;
        }

        return result;
    };

    this.getValidRanges = function getValidRanges(eventWindow, tz) {
        var validRanges = [];

        if(!!eventWindow && eventValidationsService.isValidEventTimes(eventWindow.startTime, eventWindow.endTime, tz, false)){
            var origStartTime = eventWindow.startTime;
            var origEndTime = eventWindow.endTime;
            validRanges = [{startTime: origStartTime, endTime: origEndTime}];
            var innerEvents = eventWindow.events;

            if(!!innerEvents && innerEvents.length > 0){
                var innerEventStartTime= {};
                var innerEventEndTime= {};
                innerEvents.forEach(function(innerEvent){
                    if(eventValidationsService.isValidEventTimes(innerEvent.startTime, innerEvent.endTime, tz, false)){
                        innerEventStartTime = innerEvent.startTime;
                        innerEventEndTime = innerEvent.endTime;
                        updateValidRanges(innerEventStartTime, innerEventEndTime, validRanges, origStartTime, tz, origEndTime);
                    }
                });
            }
        }


        return validRanges;
    };



    this.getServiceRanges = function getServiceRanges(chosenService, ranges, breakTimeInMinutes, allowFirstRangeBreak, tz, services, selectedTimeBetweenSlotsInMinutes) {
        function isValidGetRanges() {
            return !!ranges &&
                    ranges.length > 0 &&
                    !!chosenService &&
                    typeof chosenService.durationInMinutes === "number" && chosenService.durationInMinutes > 0 &&
                    !!services &&
                    Array.isArray(services) &&
                    services.length > 0 &&
                    services.every(x => typeof x.durationInMinutes === "number" && x.durationInMinutes > 0)
        }

        var result = [];
        if(isValidGetRanges()){
            let minService = services.reduce(function (prev, curr){
                return prev.durationInMinutes < curr.durationInMinutes ? prev : curr;
            })
            ranges.forEach(function (range) {
                if(eventValidationsService.isValidEventTimes(range.startTime, range.endTime, tz, false)){
                    splitRangeUpdateResult(range, result, chosenService.durationInMinutes, breakTimeInMinutes > 0 ? breakTimeInMinutes : 0, allowFirstRangeBreak, selectedTimeBetweenSlotsInMinutes || minService.durationInMinutes);
                }
            });
        }

        return result;
    };

    this.isValidSettingsCalPrefs = function isValidSettingsCalPrefs(calendarPref, events, windows, tz) {
        var response = {
            success: false,
            reasonCode: -1,
            reason: "unknown"
        };

        try{
            if(!isValidPrefObj(calendarPref)){
                response.reasonCode = 400;
                response.reason = "invalid calendarPref object";
                return response;
            }

            if(!this.isValidEventCalPrefs(calendarPref, events, tz)){
                response.reasonCode = 401;
                response.reason = "at least one invalid event";
                return response;
            }

            if(!this.isValidWindowCalPrefs(calendarPref, windows)){
                response.reasonCode = 402;
                response.reason = "at least one invalid window";
                return response;
            }

            response.success = true;
            response.reasonCode = 0;
            response.reason = "valid";
            return response;
        }
        catch (e) {
            return response;
        }

    };

    function eventBetweenTimes(eventStartTime, eventEndTime, minHour, maxHour) {
        return  eventStartTime.hours() < minHour ||
            eventStartTime.hours() > maxHour ||
            eventEndTime.hours() > maxHour ||
            eventEndTime.hours() === maxHour &&  eventEndTime.minutes() > 0 ||
            eventEndTime.hours() < minHour && !eventValidationsService.isEndDateMidnightSameDay(eventStartTime, eventEndTime);
    }

    function eventInHiddenDay(eventDayOfWeek, hiddenDays) {
        return hiddenDays.includes(eventDayOfWeek);
    }

    this.isValidEventCalPrefs = function isValidEventCalPrefs(calendarPref, events, tz) {
        var result = true;

        if(!Array.isArray(events)){
            return false;
        }

        if(!isValidPrefObj(calendarPref)){
            return false;
        }

        var minHour = parseInt(calendarPref.minTime.split(":")[0]);
        var maxHour = parseInt(calendarPref.maxTime.split(":")[0]);
        events.forEach(function (event) {
            if(!isValidEventObj(event)){
                result = false;
            }
            if(result === true){
                var eventStartTime = moment(event.startTime).tz(tz);
                var eventEndTime = moment(event.endTime).tz(tz);

                var eventDayOfWeek = eventStartTime.day();
                if(eventBetweenTimes(eventStartTime, eventEndTime, minHour, maxHour) || eventInHiddenDay(eventDayOfWeek, calendarPref.hiddenDays)){
                    result = false;
                }
            }
        });

        return result;

    };

    this.isValidWindowCalPrefs = function isValidWindowCalPrefs(calendarPref, windows) {
        var result = true;

        if(!Array.isArray(windows)){
            return false;
        }

        if(!isValidPrefObj(calendarPref)){
            return false;
        }

        var minHour = parseInt(calendarPref.minTime.split(":")[0]);
        var maxHour = parseInt(calendarPref.maxTime.split(":")[0]);
        for (let window of windows){
            if(!isValidWindowObj(window) || (window.durationInMinutes / 60) > (maxHour - minHour)){
                result = false;
                break;
            }
        }
        return result;
    };

    function isValidWindowObj(window) {
        return !!window
            && !!window.durationInMinutes
            && !!window.windowName;
    }

    function isValidEventObj(event) {
        return !!event
            && !!event.startTime
            && !!event.endTime;
    }

    function isValidPrefObj(calendarPref) {
        if(!!calendarPref && !!calendarPref.maxTime && !!calendarPref.minTime && !!calendarPref.hiddenDays && Array.isArray(calendarPref.hiddenDays)){
            return true;
        }

        return false;
    }

    function splitRangeUpdateResult(range, result, pickedServiceDurationInMinutes, breakTimeInMinutes, allowFirstRangeBreak, timeBetweenSlotsInMinutes) {
        var rangeSize = (range.endTime - range.startTime) / 60000;
        var breakTimeMinutesFactor = breakTimeInMinutes * 60000;
        let actualDuration = range.allowBreakOnRangeBegin === true || allowFirstRangeBreak === true ?
                             breakTimeInMinutes + pickedServiceDurationInMinutes :
                             pickedServiceDurationInMinutes
        if(actualDuration > rangeSize){
            return;
        }

        var latestEndTime;
        var timeBetweenSlotsMinutesAddFactor = timeBetweenSlotsInMinutes * 60000;
        var pickedServiceMinutesAddFactor = pickedServiceDurationInMinutes * 60000;

        var i = 0;
        var currIterationEndTime;
        var nextIterationStartTime;

        do{
            if(i === 0){
                var breakTimeToAdd = (range.allowBreakOnRangeBegin === true || allowFirstRangeBreak === true) ? breakTimeMinutesFactor : 0;
                currIterationEndTime = range.startTime + pickedServiceMinutesAddFactor + breakTimeToAdd;
                nextIterationStartTime = range.startTime + timeBetweenSlotsMinutesAddFactor + breakTimeToAdd;
                //if(currIterationEndTime > range.endTime) break;
                result.push({startTime:range.startTime + breakTimeToAdd, endTime: currIterationEndTime});
            }
            else{
                currIterationEndTime = latestEndTime + pickedServiceMinutesAddFactor + breakTimeMinutesFactor;
                nextIterationStartTime = latestEndTime + timeBetweenSlotsMinutesAddFactor + breakTimeMinutesFactor;
                //if(currIterationEndTime > range.endTime) break;
                result.push({startTime:latestEndTime + breakTimeMinutesFactor, endTime: currIterationEndTime});
            }
            latestEndTime = nextIterationStartTime;
            i++;
        } while ( (latestEndTime + pickedServiceMinutesAddFactor + breakTimeMinutesFactor) <= range.endTime);

        if(range.allowBreakOnRangeEnd){
            if(range.endTime < result[result.length - 1].endTime + breakTimeMinutesFactor){
                result.pop();
            }
        }
    }

    function updateValidRanges(innerEventStartTime, innerEventEndTime, validRanges, origStartTime, tz, origEndTime){
        validRanges.forEach(function (range) {
            var allowBreakOnRangeBegin = true;
            var allowBreakOnRangeEnd = true;
            if(range.startTime === origStartTime){
                allowBreakOnRangeBegin = false
            }
            if(range.endTime === origEndTime){
                allowBreakOnRangeEnd = false
            }
            var rangeDate = moment(range.startTime).tz(tz);
            var innerEventDate = moment(innerEventStartTime).tz(tz);

            //Check if inner event is inside current range
            if(rangeDate.format("DD/MM/YY") === innerEventDate.format("DD/MM/YY") && range.startTime < innerEventStartTime && range.endTime > innerEventEndTime){
                //remove current range
                validRanges.splice(validRanges.indexOf(range), 1);
                validRanges.push({startTime: range.startTime, endTime: innerEventStartTime, allowBreakOnRangeBegin: allowBreakOnRangeBegin, allowBreakOnRangeEnd: true});
                validRanges.push({startTime: innerEventEndTime, endTime: range.endTime, allowBreakOnRangeBegin: true, allowBreakOnRangeEnd: allowBreakOnRangeEnd});
            }
            else if(rangeDate.format("DD/MM/YY") === innerEventDate.format("DD/MM/YY") && range.startTime === innerEventStartTime && range.endTime > innerEventEndTime){
                //remove current range
                validRanges.splice(validRanges.indexOf(range), 1);
                validRanges.push({startTime: innerEventEndTime, endTime: range.endTime, allowBreakOnRangeBegin: true, allowBreakOnRangeEnd: allowBreakOnRangeEnd});
            }
            else if(rangeDate.format("DD/MM/YY") === innerEventDate.format("DD/MM/YY") && range.startTime < innerEventStartTime && range.endTime === innerEventEndTime){
                //remove current range
                validRanges.splice(validRanges.indexOf(range), 1);
                validRanges.push({startTime: range.startTime, endTime: innerEventStartTime, allowBreakOnRangeBegin: allowBreakOnRangeBegin, allowBreakOnRangeEnd: true});
            }
            else if(rangeDate.format("DD/MM/YY") === innerEventDate.format("DD/MM/YY") && range.startTime === innerEventStartTime && range.endTime === innerEventEndTime){
                //remove current range
                validRanges.splice(validRanges.indexOf(range), 1);
            }
        });
    }
};
